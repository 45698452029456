@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

body{
  background-color: #F2F2F2;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.top {
    background-color: #0073BC;
    height: 40px;
    width: 100%;
    display: block;
    padding-top: 7px;
}

.login-form{
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-form h1{
  display: flex;
  margin-top:40px;
  align-items: center;
  justify-content: center;
  color:#0073BC;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
}

.login-form h2{
  display: flex;
  align-items: center;
  justify-content: center;
  color:#0073BC;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
}

.login-container {
  display: flex;
  flex-direction: column;
  background: #fff;
  border: #D7D7D7 solid 1px;
  align-self: center;
  max-width: 402px;
  max-height: 650px;
}

.login-part {
  flex-grow: 1;
}

.login-first-row {
  min-height:85%;
}

.height100 {
  height:100%;
}

.notification-icon{
  background: url('images/nofication.png');
  height:24px;
  width: 24px;
  margin: 0 auto;
  display: inline-flex;
}

.arrow-list{
  background: url('images/arrow_list.png');
  height:20px;
  width: 20px;
  margin: 0 auto;
  display: flex;
}

.arrow-up{
  background: url('images/arrow_up.svg');
  height:18px;
  width: 15px;
  margin: 0 auto;
  display: flex;
}

.arrow-down{
  background: url('images/arrow_down.svg');
  height:17px;
  width: 15px;
  margin: 0 auto;
  display: flex;
}

.star{
  -webkit-mask: url('images/star_.svg') no-repeat 100% 100%;
  mask: url('images/star_.svg') no-repeat 100% 100%;
  -webkit-mask-size: cover;
  height:16px;
  width: 18px;
  margin: 0 auto;
  display: block;
  background-color:#717171;
  cursor: pointer;
}

.star-row{
  -webkit-mask: url('images/star.svg') no-repeat 100% 100%;
  mask: url('images/star.svg') no-repeat 100% 100%;
  -webkit-mask-size: cover;
  height:16px;
  width: 18px;
  margin: 0 auto;
  display: block;
  background-color:#717171;
  cursor: pointer;
}

.star-row-click{
  -webkit-mask: url('images/star_.svg') no-repeat 100% 100%;
  mask: url('images/star.svg') no-repeat 100% 100%;
  -webkit-mask-size: cover;
  height:16px;
  width: 18px;
  margin: 0 auto;
  display: block;
  background-color:#0073BC;
  cursor: pointer;
}

.search{
  background: url('images/search.svg');
  height:20px;
  width: 18px;
  margin-top:6px;
  margin-left:10px;
  position: absolute;
  z-index:9;
}

.clock-row{
  background: url('images/clock.svg');
  height:17px;
  width: 15px;
  margin: 0 auto;
  float: left;
  margin-right: 5px;
}

.semi-clock-row{
  background: url('images/semi_clock.svg');
  height:17px;
  width: 15px;
  margin: 0 auto;
  float: left;
  margin-right: 5px;
}

.quarter-clock-row{
  background: url('images/querter_clock.svg');
  height:17px;
  width: 15px;
  margin: 0 auto;
  float: left;
  margin-right: 5px;
}

.empty-clock-row{
  background: url('images/empty_clock.svg');
  height:17px;
  width: 15px;
  margin: 0 auto;
  float: left;
  margin-right: 5px;
}

.edit{
  background: url('images/edit_list.svg');
  height:19px;
  width: 17px;
  margin: 0 auto;
  display: inline-flex;
}

.document{
  background: url('images/document.svg');
  height:20px;
  width: 15px;
  margin: 0 auto;
  display: inline-flex;
}

.full-name{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  color: #FFFFFF;
  display: inline-flex;
  margin-right: 15px;
  cursor: pointer;
  max-width: 230px;
  max-height: 30px;
  overflow-y: hidden;
}

.logo{
  background: url('images/r2t.png');
  background-size: 100%;
  background-repeat: no-repeat;
  height:30px;
  width: 55px;
  background-size: 100%;
  margin: 0 auto;
  margin-top:0px;
  cursor: pointer;
  display: block;
}

.left-logo{
  background: url('images/r2t.png');
  background-size: 100%;
  height:30px;
  width: 55px;
  margin-left:10px;
  margin-top:0px;
  display: block;
  background-color:#0073BC;
  background-repeat: no-repeat;
}

.logo-small{
  background: url('images/r2t_logo.png');
  height:60px;
  width: 102px;
  margin: 0 auto;
  display: block;
  margin-top: 32px;
}

.file-box{
  margin-left:32px;
  margin-right: 10px;
  height: 161.55px;
  background: #FFFFFF;
  border: 1px dashed #0073BC;
  border-radius: 5px;
}

.file-add{
  background: url('images/file-add.svg');
  height:60px;
  width: 45px;
  margin: 0 auto;
  display: flex;
  margin-top: 32px;
}

.download{
  -webkit-mask: url('images/download.svg') no-repeat 100% 100%;
  mask: url('images/download.svg') no-repeat 100% 100%;
  -webkit-mask-size: cover;
  height:14px;
  width: 14px;
  margin: 0 auto;
  display: block;
  background-color:#fff;
}

.download-icon{
  -webkit-mask: url('images/download.svg') no-repeat 100% 100%;
  mask: url('images/download.svg') no-repeat 100% 100%;
  -webkit-mask-size: cover;
  height:14px;
  width: 14px;
  background-color:#fff;
}

.download-white{
  -webkit-mask: url('images/download.svg') no-repeat 100% 100%;
  mask: url('images/download.svg') no-repeat 100% 100%;
  -webkit-mask-size: cover;
  height:14px;
  width: 14px;
  margin: 0 auto;
  display: block;
  background-color:#0073BC;
}

.download-white-icon{
  -webkit-mask: url('images/download.svg') no-repeat 100% 100%;
  mask: url('images/download.svg') no-repeat 100% 100%;
  -webkit-mask-size: cover;
  height:14px;
  width: 14px;
  margin: 0 auto;
  display: block;
  background-color:#0073BC;
}

.center-item{
  margin: 0 auto !important;
}

.main-wrapper{
  max-width: 1150px;
  margin:0 auto;
}

.main-body{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  max-width: 1150px;
  background: #FFFFFF;
  border-width: 2px 2px 2px 2px;
  border-style: solid;
  border-color: #D7D7D7;
  margin: 30px auto;
  min-height: 432px;
}

.first-tab{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 10px;
  gap: 10px;
  background: radial-gradient(30.31% 555.48% at 100% 118.52%, #A9A9A9 0%, #E4E4E4 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  flex: none;
  order: 0;
  align-self: stretch;
  flex-grow: 1;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color:#717171;
  text-decoration: none !important;
}

.second-tab{
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 16px 10px;
  gap: 10px;
  background: #FFFFFF;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 1;
  color:#0073BC;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  text-decoration: none !important;
}

.MuiInput-formControl{
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 4px 8px;
    gap: 10px;
    background: #F2F2F2;
    border: 1px solid #D7D7D7;
    border-radius: 5px;
    flex: none;
    order: 1;
    align-self: stretch;
    flex-grow: 0;
}

.Mui-focused{
  border: 1px solid #0073BC !important; 
}


.MuiContainer-maxWidthSm{
  max-width: 402px !important;
}

.login-container .blue-font{
    display: flex;
    justify-content: right;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    text-align: right;
    color: #0073BC;
    text-decoration: none;
}

.MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 6px 12px !important;
}

.login-form .MuiGrid-spacing-xs-3 > .MuiGrid-item {
  padding: 1px 12px !important;
}

.login-container .button{
  max-width: 180px;
  justify-content: center;
  margin: 20px auto;
}

.button{
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-start !important;
  padding: 11px 20px !important;
  gap: 10px;
  background: #0073BC !important;
  border: 1px solid #0073BC !important;
  border-radius: 5px !important;
  color: #fff;
  text-decoration: none;
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  text-align: center !important;
  cursor: pointer !important;
}

.button span{
  text-transform: none;
}

.button-white{
  background: #ffffff !important;
  border: 1px solid #0073BC !important;
  border-radius: 5px !important;
  color: #0073BC;
  cursor: pointer;
}

.MuiButton-label{
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  text-align: center !important;
  color: #FFFFFF !important;
}

.button-white .MuiButton-label{
  color:#0073BC !important;
}

.MuiInputBase-input{
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  height: 20px !important;
  color: #373737 !important;
  padding: 0px !important;
}

.MuiFormLabel-root{
   font-family: 'Lato' !important;
   font-style: normal;
   font-weight: 400 !important;
   font-size: 12px !important;
   line-height: 14px !important;
   color: #929292 !important;
}

.MuiTextField-root{
  width: 100%;
}

.bottom-line{
  margin-top:30px;
  margin-bottom: 32px;
}

.bottom-line a{
  display: flex;
  justify-content: center;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #0073BC;
  text-decoration: none;
  margin-top:20px;
}

.validation-error{
  color:#FF3333;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.validation-green-error{
  color:#008000;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}

.right-element{
  text-align: right;
}

.padding-body{
  padding:20px 50px 50px 50px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.MuiTableCell-head
{
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #373737 !important;
}

.MuiTableCell-body{
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  color: #979797 !important;
}

.yellow-dot {
  height: 12px;
  width: 12px;
  background-color: #FFE600;
  border-radius: 50%;
  display: inline-block;
}

.green-dot {
  height: 12px;
  width: 12px;
  background-color: #7BFF66;
  border-radius: 50%;
  display: inline-block;
}

.red-dot {
  height: 12px;
  width: 12px;
  background-color: #FF4949;
  border-radius: 50%;
  display: inline-block;
}

.search-field {
  margin-top:50px !important;
  margin-right: 50px !important;
}

.add-search-box .MuiInputBase-input {
  padding:0;
}

.add-search-box .search{
  margin-top:7px;
}

.add-search-box-item{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #373737;
    border: 1px solid #D7D7D7 !important;
    border-radius: 5px;
    padding: 0px;
    margin-top:10px !important;
    display: flex;
    cursor: pointer;
}

.add-search-box-item:hover{
  background-color: #CCF0FF;
}

.add-search-box-item p{
  line-height: 16px;
  margin: 10px !important;
}

.search-input input{
  padding-left: 30px !important;
}

.menu li{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px;
  gap: 19px;
  width: 230px;
  min-height: 24px;
  background: #0073BC;
  border-bottom: 1px solid #FFFFFF;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #FFFFFF;
  cursor: pointer !important;
  text-align: left !important;
}

.menu li a{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 17px;
  cursor: pointer !important;
  text-align: left !important;
  text-decoration: none;
  color:#fff;
  word-wrap: break-word;
  overflow-wrap: anywhere;
}

.menu li:last-child{
  border-radius: 0px 5px 5px 5px;
}

.menu{
  z-index: 9999;
  position: absolute;
  margin-top:5px;
  margin-right: 0px;
  cursor: pointer !important;
  margin-left: -50px !important;
}

.MuiTableCell-root{
  padding: 0 !important;
}

.document-list {
  overflow-x: hidden !important;
}

.document-list .button{
    padding: 6px 20px !important;
    gap: 10px;
    width: 43px;
    background: #0073BC;
    border: 1px solid #0073BC !important;
    border-radius: 5px !important;
}

.document-list .button-small{
  padding: 6px 5px !important;
  gap: 10px;
  width: 27px;
  min-width: 20px;
  height: 28px;
  background: #fff !important;
  border: 1px solid #0073BC;
  border-radius: 5px;
}

.document-list .button-small-header{
  padding: 6px 5px !important;
  gap: 10px;
  width: 27px;
  min-width: 20px;
  height: 28px;
  background: #0073BC !important;
  border: 1px solid #0073BC;
  border-radius: 5px;
}

.document-list table tr td{
  padding: 0 10px !important;
  white-space: nowrap;
  overflow: hidden;
}

.document-list table tr td:first-child{
  padding: 0 0px !important;
}

.sortColumn span:nth-child(2){
  position: absolute;
  margin-top: -3px;
}


.document-list table tr th{
  padding: 0 10px !important;
}

.document-list table tr th:nth-child(5){
  width: 150px !important;
}

.document-list table tr th:nth-child(4){
  width: 140px !important;
}

.document-list table tr th:first-child{
  padding: 0 0px !important;
}

.MuiTablePagination-toolbar p:first-child{
  display: none !important;
}

.MuiTablePagination-selectRoot{
  display: none !important;
}

.show-pdf{
  width: 91px;
  height: 161.55px;
  background: #F2F2F2;
  border-radius: 5px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color:#717171;
  align-items: center;
  display: flex;
  cursor: pointer !important;
}

.white-show-pdf{
  width: 91px;
  height: 161.55px;
  background: #0073BC;
  border-radius: 5px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color:#fff;
  align-items: center;
  display: flex;
  cursor: pointer !important;
}

.padding-20{
  padding:0 30px;
}

.border-grid{
  border-top: 2px solid #D7D7D7;
  border-bottom: 2px solid #D7D7D7;
  min-height: 220px;
}

.sign-body .border-grid{
  border-bottom: none;
}

.border-top{
  border-top: 2px solid #D7D7D7;
  min-height: 30px;
  padding-top:20px;
}

.border-first{
  border-right: 2px solid #D7D7D7;
}

.file-info{
  width:350px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #373737;
  display: flex;
  justify-self: center;
  margin: 0 auto;
  margin-top: 10px;
}

.file-info2{
  width:250px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #373737;
  display: flex;
  justify-self: center;
  margin: 0 auto;
}

h2{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #0073BC;
  text-align: center;
  margin: 0;
  margin-top:16px;
}

.border-grid h4{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #0073BC;
  margin: 10px 0;
}

.border-grid h5{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  margin:8px;
  color: #0073BC;
}

.box-signers{
  padding-left: 32px;
  padding-right: 32px;
}

.button-person{
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px 16px !important;
  gap: 10px !important;
  background: #FFFFFF;
  border: 1px solid #0073BC !important;
  border-radius: 5px !important;
}

.button-person span{
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  text-align: center !important;
  color: #0073BC !important;
}

.width98{
  width: 100%;
}

.box-signers-radio-info{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #373737;
  margin: 10px 0px 0px 0px;
}

.radio label{
  height: 30px;
}

.radio span{
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  display: flex !important;
  align-items: center !important;
  color: #373737;
}

.button-sign{
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px 16px !important;
  gap: 10px !important;
  background: #FFFFFF !important;
  border: 1px solid #0073BC !important;
  border-radius: 20px !important;
  width: 100% !important;
  margin-left:10px;
}

.button-sign span{
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  text-align: center !important;
  color: #0073BC !important;
}

.button-sign-active{
  display: flex !important;
  flex-direction: row !important;
  justify-content: center !important;
  align-items: center !important;
  padding: 8px 16px !important;
  gap: 10px !important;
  background: #0073BC !important;
  border: 1px solid #0073BC !important;
  border-radius: 20px !important;
  width: 100% !important;
  margin-left:10px;
}

.button-sign-active span{
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  text-align: center !important;
  color: #ffffff !important;
}

.add-search-box{
  border: 1px solid #0073BC !important;
  border-radius: 5px !important;
  padding:10px;
}

.add-person-box{
  border: 1px solid #0073BC !important;
  border-radius: 5px !important;
  padding:10px;
}

.add-person-box .MuiInput-formControl{
  background: #fff;
  border:none;
  border-bottom: 1px solid #717171 !important;
  border-radius: 0;
}

.add-person-box .button-person{
  margin-top:10px;
}

.add-person-box .Mui-focused{
  border: none !important; 
  border-bottom: 1px solid #717171 !important;
}

.x{
  background: url('images/x.svg');
  cursor: pointer !important;
  width: 9px;
  margin-right: 5px;;
  height: 10px;
  display: flex;
  margin-top:10px;
  float: right;
}

.dots{
  background: url('images/dots.svg');
  cursor: pointer;
  width: 2px;
  height: 10px;
  display: flex;
  margin-top:15px;
  float: right;
}

.back{
  background: url('images/back.svg');
  height:10px;
  width: 5px;
  margin: 0 auto;
  display: flex;
  position: absolute;
  margin-left: -10px;
  margin-top: 2px;
}

.edit-icon{
  background: url('images/edit.svg');
  height:20px;
  width: 20px;
  display: flex;
  margin-top:10px;
  margin-left:0px;
}

.edit-sign-icon{
  background: url('images/edit_sign.svg');
  height:14px;
  width: 15px;
  display: flex;
  margin-left:0px;
  background-size: 100%;
}

.box-item{
  display: flex;
  flex-direction: row;
  padding: 2px 12px;
  gap: 1px;
  width: 528px;
  min-height: 43px;
  background: #CCF0FF;
  border: 1px solid #D7D7D7;
  border-radius: 5px;
  margin-bottom: 10px !important;
  cursor: pointer;
}

.box-item p{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #373737;
  padding-top:0px;
  margin: 5px 0px;
}

.box-item .x{
  margin-top:15px;
}

.box-item1{
  background:rgba(115, 213, 255, 0.8) !important;
}

.box-item2{
  background:rgba(115, 213, 255, 0.4) !important;
}

.box-item3{
  background:rgba(115, 213, 255, 0.2) !important;
}

.box-item4{
  background:#FECC48;
}

.box-item5{
  background:rgba(254, 204, 72, 0.8) !important;
}

.box-item6{
  background:rgba(254, 204, 72, 0.4) !important;
}

.box-item7{
  background:rgba(254, 204, 72, 0.2) !important;
}

.sign-box{
  width: 484px;
  height: 684px;
  background: #FFFFFF;
  border: 1px solid #0073BC;
  margin:24px 43px;
  position:relative;
}

.sign-box2{
  width: 484px;
  height: 684px;
  background: #FFFFFF;
  margin:24px 43px;
}

.sign-box-shadow{
  width: 484px;
  height: 684px;
  opacity: 1;
}

.sign-document-label{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #929292;
  margin: 5px 0px;
}

.sign-document-name{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #373737;
  margin: 5px 0px;
}

.bottom-items{
  position: absolute;
  bottom: -20px;
}

.relative {
  position: relative;
}

.button-cancel{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 11px 34px !important;
  text-align: center;
  gap: 10px;
  background: #FFFFFF !important;
  border: 1px solid #0073BC;
  border-radius: 5px;
  text-decoration: none;
  color:#717171 !important;
}

.full-width{
  width: 100%;
}

.box-item-margin{
  margin-top:5px !important;
}

.last{
  background: url('images/last.svg') no-repeat 5px 5px;
  height:20px;
  width: 20px;
  margin: 0 auto;
  display: flex;
  border-radius: 50%;
  border: 1px solid #717171;
}

.prev{
  background: url('images/prev.svg') no-repeat 6px 6px;
  height:20px;
  width: 20px;
  margin: 0 auto;
  display: flex;
  border-radius: 50%;
  border: 1px solid #717171;
}

.next{
  background: url('images/next.svg') no-repeat 7px 5px;
  height:20px;
  width: 20px;
  margin: 0 auto;
  display: flex;
  border-radius: 50%;
  border: 1px solid #717171;
}

.first{
  background: url('images/first.svg') no-repeat 5px 5px;
  height:20px;
  width: 20px;
  margin: 0 auto;
  display: flex;
  border-radius: 50%;
  border: 1px solid #717171;
}

.remove{
  background: url('images/remove.svg') no-repeat 2px 2px;
  height:10px;
  width: 10px;
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid #717171;
  position: absolute;
  right: 0px;
  top: -20px;
  cursor: pointer;
}

.copy{
  background: url('images/copy.svg') no-repeat -3px -3px;
  height:10px;
  width: 10px;
  margin: 0 auto;
  border-radius: 50%;
  border: 1px solid #717171;
  position: absolute;
  right: 15px;
  top: -20px;
  cursor: pointer;
}

.center-page{
  margin:0 auto;
  width: 120px !important;
  margin-top: -10px;
  margin-bottom: 30px;
}

.center{
  text-align: center;
}

.person-sign{
  border:solid 3px #73D5FF;
  width: 110px;
  height: 42px;
  position: absolute;
  margin-top:-685px;
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.person-sign1{
  border:solid 3px rgba(115, 213, 255, 0.8) !important;
}

.person-sign2{
  border:solid 3px rgba(115, 213, 255, 0.4) !important;
}

.person-sign3{
  border:solid 3px rgba(115, 213, 255, 0.2) !important;
}

.person-sign4{
  border:solid 3px #FECC48 !important;
}

.person-sign5{
  border:solid 3px rgba(254, 204, 72, 0.8) !important;
}

.person-sign6{
  border:solid 3px rgba(254, 204, 72, 0.4) !important;
}

.person-sign7{
  border:solid 3px rgba(254, 204, 72, 0.2) !important;
}

.person-sign-back0{
  border:solid 3px #73D5FF;
  background:#CCF0FF;
  opacity: 50%;
}

.person-sign-back1{
  border:solid 3px rgba(115, 213, 255, 0.8) !important;
  background:rgba(115, 213, 255, 0.8) !important;
  opacity: 50%;
}

.person-sign-back2{
  border:solid 3px rgba(115, 213, 255, 0.4) !important;
  background:rgba(115, 213, 255, 0.4) !important;
  opacity: 50%;
}

.person-sign-back3{
  border:solid 3px rgba(115, 213, 255, 0.2) !important;
  background:rgba(115, 213, 255, 0.2) !important;
  opacity: 50%;
}

.person-sign-back4{
  border:solid 3px #FECC48;
  background:#FECC48;
  opacity: 50%;
}

.person-sign-back5{
  border:solid 3px rgba(254, 204, 72, 0.8) !important;
  background:rgba(254, 204, 72, 0.8) !important;
  opacity: 50%;
}

.person-sign-back6{
  border:solid 3px rgba(254, 204, 72, 0.4) !important;
  background:rgba(254, 204, 72, 0.4) !important;
  opacity: 50%;
}

.person-sign-back7{
  border:solid 3px rgba(254, 204, 72, 0.2) !important;
  background:rgba(254, 204, 72, 0.2) !important;
  opacity: 50%;
}

.textLayer {
  -moz-user-select: -moz-none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -o-user-select: none;
  user-select: none;
}

.validation-document{
  margin-left:30px;
}

.blur{
  filter: blur(8px); 
  -webkit-filter: blur(8px);
}

.MuiDialogContentText-root{
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-align: center !important;
  color: #000000 !important;
}

.MuiDialogActions-root{
  padding:16px !important;
}

.user {
  width: 857px;
}

.dialog-user h2{
  text-align: left !important;
  margin-top:32px;
  margin-left:0px;
}

.dialog-sign h2{
  text-align: left !important;
  margin-top:15px;
  margin-left:0px;
  margin-bottom: -10px;
}

.right-side{
  justify-content: right;
}

.user td{
  padding: 8px !important;
}

.user th{
  padding: 8px !important;
}

.action-margin{
  margin-top:25px !important;
  padding-right: 15px;
  margin-bottom: 32px !important;
}

.dialog-sign .action-margin{
  margin-top: 10px !important;
}

.center-vertical-item{
  height: 100% !important;
  min-height: 100vh;
}

.MuiInputBase-input:focus{
  border:none !important;
}

.MuiInput-underline::after{
  border:none !important;
}

.MuiInput-underline::before{
  border:none !important;
}

.MuiInput-underline:hover:not(.Mui-disabled):before{
  border-bottom: none !important;
}

.sigCanvas{
  border: 1px solid #000;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: #0073BC !important;
}

.blink {
  animation: blinker 2s step-start infinite;
}

@keyframes blinker {
  50% {
    background: #E4F5FF;
  }
}

.MuiButton-root{
  text-transform: none !important;
}

.file-box-name{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #373737;
    margin-top: 55px;
}

.file-box-uploaded{
    font-family: 'Lato' !important;
    font-style: normal !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 14px !important;
    text-align: center !important;
    color: #0073BC !important;
}

.radio span{
    color: #0073BC; 
}

.MuiFormControlLabel-label{
    color: #373737 !important;
}

.add-person-box .MuiFormControlLabel-label{
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  margin-top: 1px;
}

.add-person-box .MuiFormControlLabel-root{
  margin-top:10px;
  margin-right: 0px;
}

.gray-icon{
  fill:#999999 !important; 
}

.person-name{
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 10px !important;
  line-height: 12px !important;
  color: #000000 !important;
  display: block;
}

.upload-file-box{
  max-width:calc(100% - 91px) !important;
}

.user table tr th{
  padding: 10px !important;
}

.document-list table tr td{
  padding: 10px !important;
}

.signers-box{
  height:665px;
}

.only-mobile{
  display: none;
}

.not-mobile{
  display: block;
}

@media (max-width: 1150px) {
  .main-body{
    max-width:calc(100% - 50px) !important;
  } 

  .file-info{
    font-size: 14px;
    max-width:calc(100% - 50px) !important;
  }

  .upload-file-box{
    max-width:calc(100% - 120px) !important;
  }

  .sign-body .border-first {
    max-width: 100% !important;
    border-right: none !important;
    flex-basis:auto !important;
  }

  .sign-body .border-second {
    max-width: 100% !important;
    flex-basis:auto !important;
  }

  .document-list {
    overflow-x: auto !important;
  }
}

@media (max-width: 750px) {

  .border-first {
    max-width: 100% !important;
    border-right: none !important;
    flex-basis:auto !important;
  }

  .border-second {
    max-width: 100% !important;
    flex-basis:auto !important;
  }
}

@media only screen and (max-width: 820px) {
  .button-cancel{
    padding: 11px 27px !important;
  }

  .file-info{
    font-size: 9px;
    max-width: calc(100% - 30px) !important;
  }

  .signers-box{
    height:auto;
  }

  .signers-box2{
    margin-top:20px !important;
  }

  .only-mobile{
    display: block;
    margin-bottom:10px;
  }

  .not-mobile{
    display: none;
  }

  .clock-row{
    background: url('images/mobile/clock.svg');
    height:14px;
    width: 14px;
    margin: 0 auto;
    margin-right: 5px;
    display: inline-block;
    float:none;
  }
  
  .semi-clock-row{
    background: url('images/mobile/semi_clock.svg');
    height:14px;
    width: 14px;
    margin: 0 auto;
    margin-right: 5px;
    display: inline-block;
    float:none;
  }
  
  .quarter-clock-row{
    background: url('images/mobile/querter_clock.svg');
    height:14px;
    width: 14px;
    margin: 0 auto;
    margin-right: 5px;
    display: inline-block;
    float:none;
  }
  
  .empty-clock-row{
    background: url('images/mobile/empty_clock.svg');
    height:14px;
    width: 14px;
    margin: 0 auto;
    margin-right: 5px;
    display: inline-block;
    float:none;
  }
  
  .document-box p{
    margin: 0;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color:#373737;
    white-space: nowrap;
  }
  
  .document-box p:nth-child(2){
    margin: 0;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color:#373737;
    white-space: nowrap;
    margin-top:5px;
  }
  
  .document-box p:nth-child(3){
    margin: 0;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color:#373737;
    white-space: nowrap;
  }
  
  .document-box span{
    color:#979797;
  }
  
  .description p{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #373737;
    margin:10px 0px;
  }
  
  .description-small p{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #373737;
    margin:5px 0px;
  }
  
  .description-small p span{
    color:#979797;
  }

  .document-box{
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 10px;
    gap: 16px;
    height: 73px;
    background: #CCF0FF;
    border: 1px solid #D7D7D7;
    border-radius: 5px;
    margin-top:11px !important;
  }
  
  .wrapper {
    padding:0 20px 0px 20px !important;
  }
  
  .search-field{
    margin-top:0px !important;
    width: 100%;
    margin-right: 0px !important;
  }

  .search-input{
    height: 34px;
  }
  
  .search-input input{
    padding-left: 30px !important;
  }

  .document{
    margin-top:10px;
  }

  .edit{
    margin-top:10px;
  }

  .dialog-sign .button-cancel{
    padding:11px 10px !important;
  }

  .dialog-sign .action-margin{
    margin-top:0px !important;
  }

  .box-item-margin {
    font-size: 13px !important;
  }

  .first-tab{
    padding: 2px 10px;
    font-size: 14px;
    min-height: 60px;
  }

  .second-tab{
    font-size:14px;
    padding: 2px 10px;
    min-height: 60px;
  }
}

@media (max-width: 500px) {

  .full-name{
    font-size:12px;
  }

  .menu{
    margin-left:-135px !important;
  }

  .button-cancel{
    padding: 11px 27px !important;
  }

  .file-info{
    font-size: 9px;
    max-width: calc(100% - 30px) !important;
  }

  .sign-body{
    zoom: 0.580;
    max-width:calc(100% - 100px) !important;
    margin: 30px auto;
  }

  .signers-box{
    height:auto;
  }

  .signers-box2{
    margin-top:20px !important;
  }

  .only-mobile{
    display: block;
    margin-bottom:10px;
  }

  .not-mobile{
    display: none;
  }

  .clock-row{
    background: url('images/mobile/clock.svg');
    height:14px;
    width: 14px;
    margin: 0 auto;
    margin-right: 5px;
    display: inline-block;
    float:none;
  }
  
  .semi-clock-row{
    background: url('images/mobile/semi_clock.svg');
    height:14px;
    width: 14px;
    margin: 0 auto;
    margin-right: 5px;
    display: inline-block;
    float:none;
  }
  
  .quarter-clock-row{
    background: url('images/mobile/querter_clock.svg');
    height:14px;
    width: 14px;
    margin: 0 auto;
    margin-right: 5px;
    display: inline-block;
    float:none;
  }
  
  .empty-clock-row{
    background: url('images/mobile/empty_clock.svg');
    height:14px;
    width: 14px;
    margin: 0 auto;
    margin-right: 5px;
    display: inline-block;
    float:none;
  }
  
  .document-box p{
    margin: 0;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color:#373737;
    white-space: nowrap;
  }
  
  .document-box p:nth-child(2){
    margin: 0;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color:#373737;
    white-space: nowrap;
    margin-top:5px;
  }
  
  .document-box p:nth-child(3){
    margin: 0;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color:#373737;
    white-space: nowrap;
  }
  
  .document-box span{
    color:#979797;
  }
  
  .description p{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #373737;
    margin:10px 0px;
  }
  
  .description-small p{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #373737;
    margin:5px 0px;
  }
  
  .description-small p span{
    color:#979797;
  }

  .document-box{
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 10px;
    gap: 16px;
    width: 350px;
    height: 73px;
    background: #CCF0FF;
    border: 1px solid #D7D7D7;
    border-radius: 5px;
    margin-top:11px !important;
    max-width: 320px!important;
  }
  
  .wrapper {
    padding:0 20px 0px 20px !important;
  }
  
  .search-field{
    margin-top:0px !important;
    width: 100%;
    margin-right: 0px !important;
  }

  .search-input{
    height: 34px;
  }
  
  .search-input input{
    padding-left: 30px !important;
  }

  .document{
    margin-top:10px;
  }

  .edit{
    margin-top:10px;
  }

  .dialog-sign .button-cancel{
    padding:11px 10px !important;
  }

  .dialog-sign .action-margin{
    margin-top:0px !important;
  }

  .box-item-margin {
    font-size: 13px !important;
  }

  .first-tab{
    padding: 2px 10px;
    font-size: 14px;
    min-height: 60px;
  }

  .second-tab{
    font-size:14px;
    padding: 2px 10px;
    min-height: 60px;
  }

  .mobile-buttons{
    justify-content: center !important;
    padding-left:10px;
  }
}

@media (orientation: landscape) and (max-width: 1200px ){
  .MuiDialog-paper{
    max-height: calc(100% ) !important;
  }

  .only-mobile{
    display: block;
    margin-bottom:10px;
  }

  .not-mobile{
    display: none;
  }

  .MuiDialogActions-root{
    padding: 0px 16px !important;
    margin-top: -20px;
  }

  .clock-row{
    background: url('images/mobile/clock.svg');
    height:14px;
    width: 14px;
    margin: 0 auto;
    margin-right: 5px;
    display: inline-block;
    float:none;
  }
  
  .semi-clock-row{
    background: url('images/mobile/semi_clock.svg');
    height:14px;
    width: 14px;
    margin: 0 auto;
    margin-right: 5px;
    display: inline-block;
    float:none;
  }
  
  .quarter-clock-row{
    background: url('images/mobile/querter_clock.svg');
    height:14px;
    width: 14px;
    margin: 0 auto;
    margin-right: 5px;
    display: inline-block;
    float:none;
  }
  
  .empty-clock-row{
    background: url('images/mobile/empty_clock.svg');
    height:14px;
    width: 14px;
    margin: 0 auto;
    margin-right: 5px;
    display: inline-block;
    float:none;
  }
  
  .document-box p{
    margin: 0;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color:#373737;
    white-space: nowrap;
  }
  
  .document-box p:nth-child(2){
    margin: 0;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color:#373737;
    white-space: nowrap;
    margin-top:5px;
  }
  
  .document-box p:nth-child(3){
    margin: 0;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color:#373737;
    white-space: nowrap;
  }
  
  .document-box span{
    color:#979797;
  }
  
  .description p{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    color: #373737;
    margin:10px 0px;
  }
  
  .description-small p{
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #373737;
    margin:5px 0px;
  }
  
  .description-small p span{
    color:#979797;
  }

  .document-box{
    box-sizing: border-box;
    /* Auto layout */
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 10px;
    gap: 16px;
    width: 350px;
    height: 73px;
    background: #CCF0FF;
    border: 1px solid #D7D7D7;
    border-radius: 5px;
    margin-top:11px !important;
  }
  
  .wrapper {
    padding:0 20px 0px 20px !important;
  }
  
  .search-field{
    margin-top:0px !important;
    width: 100%;
    margin-right: 0px !important;
  }

  .search-input{
    height: 34px;
  }
  
  .search-input input{
    padding-left: 30px !important;
  }

  .document{
    margin-top:10px;
  }

  .edit{
    margin-top:10px;
  }

  .mobile-buttons{
    justify-content: center !important;
  }
}

.loader
{
  animation: loading-spinner 0.7s linear infinite;
  background: url('images/loader_white.svg');
  height:10px;
  width: 10px;
  margin: 0 auto;
  display: flex;
}

.loader-blue
{
  animation: loading-spinner 0.7s linear infinite;
  background: url('images/loader_blue.svg');
  height:10px;
  width: 10px;
  margin: 0 auto;
  display: flex;
}

@keyframes loading-spinner
{
  from{
    transform: rotate(0deg)
  }
  to
  {
    transform: rotate(360deg)
  }
}

.dot-href{
  color:#0073BC;
  font-size: 12px; 
}

.full-name span{
  display: contents;
  overflow-y: hidden;
}

input, textarea {
  font-size: initial;
}

.info{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 5px;
  gap: 12px;
  height: 58px;
  background: #F4F4F4;
  border-radius: 10px;
}

.info p{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #373737;
  margin:0;
}

.info-svg{
  background: url('images/info.svg');
  width: 19px;
  height: 24px;
  display: flex;
  margin-top:0px;
  margin-left:10px;
}

.sign-quantity{
  text-align: center;
  margin-top:0px;
}

.not-show{
  display: none;
}

.popup{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  gap: 24px;
  position: absolute;
  max-width: 470px;
  background: #0073BC;
  box-shadow: 0px 0px 20px rgba(0, 115, 188, 0.2);
  border-radius: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999999;
  margin-top: 50px;
}

.popup h5{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #fff;
  margin: 5px 0;
}

.popup p{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}

.popup .x{
  background: url('images/x_white.svg');
  cursor: pointer !important;
  width: 14px;
  margin-right: 5px;;
  height: 14px;
  display: flex;
  margin-top:10px;
  float: right;
}

.popup button {
  display: flex;
  flex-direction: row;
  align-self: center;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;
  gap: 10px;
  width: 300px;
  height: 45px;
  background: #FFFFFF !important;
  border: 1px solid #0073BC;
  border-radius: 5px;
  text-align: center;
  margin: 0 auto;
  margin-top:10px;
}

.popup button span {
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 17px !important;
  text-align: center;
  color: #0073BC !important;
}

.popup-dialog{
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px;
  gap: 32px;
  max-width: 586px;
  background: #FFFFFF;
  border: 1px solid #F2F2F2;
  box-shadow: 0px 0px 15px rgba(0, 115, 188, 0.5);
  border-radius: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 999999;
  position: absolute;
  margin-top:10%;
}

.popup-dialog h5{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  color: #0073BC;
  margin: 0;
  margin-top:5px;
}

.popup-dialog p{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #0073BC;
  margin: 0;
}

.payLoad-info{
  margin-top:20px !important;
  margin-bottom:20px !important;
}

.popup-dialog .button-cancel span{
  font-family: 'Lato' !important;
  font-style: normal !important;
  font-weight: 600 !important;
  font-size: 12px !important;
  line-height: 14px !important;
  text-align: center !important;
  color: #717171 !important;
}

.popup-dialog .button-cancel{
  padding: 11px 20px !important;
  margin-right: 10px;
}

.popup-dialog button{
  float: right;
}

.popup-dialog .action-margin{
  padding-right: 0;
}

/*PayLoad*/

.payload .container * {
	font-family: Arial, Helvetica, sans-serif;
	font-size: 14px;
	color: #ffffff;
}

.payload .container {
	text-align: center;
	width: 420px;
	margin: 20px auto 10px;
	display: block;
	border-radius: 5px;
	box-sizing: border-box;
}

.payload .card-container {
	width: 100%;
	margin: 0 auto;
	border-radius: 6px;
	padding: 10px;
	background: rgb(2,0,60);
	text-align: left;
	box-sizing: border-box;
}

.payload .card-container aside {
	padding-bottom: 6px;
}

.payload .payu-card-form {
	background-color: #ffffff;
	padding: 5px;
	border-radius: 4px;
}

.payload .card-details {
	clear: both;
	overflow: auto;
	margin-top: 10px;
}

.payload .card-details .expiration {
	width: 50%;
	float: left;
	padding-right: 5%;
}

.payload .card-details .cvv {
	width: 45%;
	float: left;
}

.payload button {
	border: none;
	background: #438F29;
	padding: 8px 15px;
	margin: 10px auto;
	cursor: pointer;
}

.payload .response-success {
	color: #438F29;
}

.payload .response-error {
	color: #990000;
}

.signer {
  font-size:13px;
}